<template>
  <v-container grid-list-xs v-if="!loading && show">
    <v-layout row wrap>
      <v-flex xs12 sm4> <h4>Preferred Providers.</h4> </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm8>
        <v-chip close class="mr-2">
          <v-avatar> <v-icon>people</v-icon> </v-avatar>
          Homefood / CFO
        </v-chip>

        <v-chip close class="mr-2">
          <v-avatar> <v-icon small>fas fa-truck</v-icon> </v-avatar>
          Food Trucks
        </v-chip>
        <v-chip close class="mr-2">
          <v-avatar> <v-icon>fas fa-concierge-bell</v-icon> </v-avatar>
          Catering Service
        </v-chip>
        <v-chip close>
          <v-avatar> <v-icon>fas fa-utensils</v-icon> </v-avatar>
          Restaurant
        </v-chip>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm8> <v-divider class="py-3"></v-divider> </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm4> <h4>Foods that I like.</h4> </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm4>
        <food-diet
          :init="liked_diets"
          @updateDiets="updateDiets($event, true)"
        />
      </v-flex>
      <v-flex xs12 sm4>
        <food-origin
          :init="liked_cuisine"
          @updateOrigins="updateCuisine($event, true)"
        />
      </v-flex>
      <v-flex xs12 sm4>
        <food-ingredients
          :init="liked_ingredients"
          @updateIngredients="updateIngredients($event, true)"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm8> <v-divider class="py-3"></v-divider> </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 sm4> <h4>Foods that I don't like that much.</h4> </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm4>
        <food-diet
          :init="disliked_diets"
          @updateDiets="updateDiets($event, false)"
        />
      </v-flex>
      <v-flex xs12 sm4>
        <food-origin
          :init="disliked_cuisine"
          @updateOrigins="updateCuisine($event, false)"
        />
      </v-flex>
      <v-flex xs12 sm4>
        <food-ingredients
          :init="disliked_ingredients"
          @updateIngredients="updateIngredients($event, false)"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-btn color="primary" class="mr-3" @click.prevent="save">Save</v-btn>
        <!-- <v-btn color="" router :to="'/settings'">Close</v-btn> -->
        <v-btn color="" router :to="'/settings'">Close</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import FoodOrigin from '@/components/common/FoodOrigin'
import FoodDiet from '@/components/common/FoodDiet'
import FoodIngredients from '@/components/common/FoodIngredients'
export default {
  components: {
    FoodOrigin,
    FoodDiet,
    FoodIngredients
  },
  data() {
    return {
      liked_cuisine: null,
      liked_ingredients: null,
      liked_diets: null,
      disliked_cuisine: null,
      disliked_ingredients: null,
      disliked_diets: null,
      show: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      settings: 'userSettings'
    })
  },
  mounted() {
    this.$store.dispatch('getUserSettings', 'meal_preferences').then(data => {
      if (data && data.settings) {
        this.liked_ingredients = data.settings.liked_ingredients
        this.liked_cuisine = data.settings.liked_cuisine
        this.liked_diets = data.settings.liked_diets
        this.disliked_ingredients = data.settings.disliked_ingredients
        this.disliked_cuisine = data.settings.disliked_cuisine
        this.disliked_diets = data.settings.disliked_diets
      }
      this.show = true
      console.log('----')
      console.log(data)
    })
  },
  methods: {
    updateCuisine: function(payload, liked) {
      console.log('payload', payload, liked)
      if (liked) {
        this.liked_cuisine = payload
      } else {
        this.disliked_cuisine = payload
      }
    },
    likedCuisineList: function() {
      let a = new set(liked_cuisine)
      let b = new set(disliked_cuisine)
      return list(set(a) - set(b))
    },
    updateIngredients: function(payload, liked) {
      console.log('payload', payload, liked)
      if (liked) {
        this.liked_ingredients = payload
      } else {
        this.disliked_ingredients = payload
      }
    },
    updateDiets: function(payload, liked) {
      console.log('payload', payload, liked)

      if (liked) {
        this.liked_diets = payload
      } else {
        this.disliked_diets = payload
      }
    },
    save() {
      this.$store.dispatch('saveSettings', {
        // type: 'meal_preferences',
        liked_cuisine: this.liked_cuisine,
        liked_ingredients: this.liked_ingredients,
        liked_diets: this.liked_diets,
        disliked_cuisine: this.disliked_cuisine,
        disliked_ingredients: this.disliked_ingredients,
        disliked_diets: this.disliked_diets
      })
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.view)?_c('div',_vm._l((_vm.model),function(o){return _c('v-chip',{key:o.text,attrs:{"color":o.color + ' lighten-3',"label":"","small":""}},[_vm._v("\n      "+_vm._s(o.text))])}),1):_c('div',[_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"readonly":_vm.readonly,"hide-selected":"","label":_vm.$t('Common.diets'),"delimiters":[',', ';'],"hint":"Ethnicity","multiple":"","small-chips":"","solo":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return (item === Object(item))?[_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1)]:undefined}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
var parent = ref.parent;
return [_c('v-list-item-content',[(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"dark":"","label":"","small":""}},[_vm._v("\n            "+_vm._s(item.text)+"\n          ")])],1),_c('v-spacer'),(item.iso)?_c('flag',{attrs:{"iso":item.iso}}):_vm._e(),(item.editable)?_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing !== item ? 'edit' : 'check'))])],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('template',{slot:"no-data"},[_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("Create")]),_c('v-chip',{attrs:{"color":((_vm.colors[_vm.nonce - 1]) + " lighten-3"),"label":"","small":""}},[_vm._v("\n            "+_vm._s(_vm.search)+"\n          ")])],1)],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
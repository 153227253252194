<template>
  <div>
    <div v-if="view">
      <v-chip
        v-for="o in model"
        :color="o.color + ' lighten-3'"
        label
        small
        :key="o.text"
      >
        {{ o.text }}</v-chip
      >
    </div>
    <div v-else>
      <v-combobox
        v-model="model"
        :filter="filter"
        :hide-no-data="!search"
        :items="items"
        :search-input.sync="search"
        :readonly="readonly"
        hide-selected
        :label="$t('Common.diets')"
        :delimiters="[',', ';']"
        hint="Ethnicity"
        multiple
        small-chips
        solo
      >
        <template slot="no-data">
          <v-list-item>
            <span class="subheading">Create</span>
            <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
              {{ search }}
            </v-chip>
          </v-list-item>
        </template>
        <template
          v-if="item === Object(item)"
          slot="selection"
          slot-scope="{ item, parent, selected }"
        >
          <v-chip
            :color="`${item.color} lighten-3`"
            :input-value="selected"
            label
            small
          >
            <span class="pr-2"> {{ item.text }} </span>
            <v-icon small @click="parent.selectItem(item)">close</v-icon>
          </v-chip>
        </template>
        <template slot="item" slot-scope="{ index, item, parent }">
          <v-list-item-content>
            <v-text-field
              v-if="editing === item"
              v-model="editing.text"
              autofocus
              flat
              background-color="transparent"
              hide-details
              solo
              @keyup.enter="edit(index, item)"
            ></v-text-field>
            <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
              {{ item.text }}
            </v-chip>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <flag v-if="item.iso" :iso="item.iso" />
          <v-list-item-action v-if="item.editable" @click.stop>
            <v-btn icon @click.stop.prevent="edit(index, item)">
              <v-icon>{{ editing !== item ? 'edit' : 'check' }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </v-combobox>
    </div>
  </div>
</template>
<script>
import { diets } from '@/data/static'
import { mapGetters } from 'vuex'

export default {
  props: {
    init: {
      Type: Array
    },
    readonly: {
      Type: Boolean
    },
    view: {
      Type: Boolean
    }
  },
  data: () => ({
    activator: null,
    attach: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange', 'red'],
    editing: null,
    index: -1,
    items: [{ header: 'Select a food type' }],
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0
  }),
  computed: {
    ...mapGetters({
      // dietList: 'diets',
      // currentOrigins: 'currentOrigins'
    })
  },
  beforeMount() {
    if (this.init) {
      this.model = this.init
    }
    for (let index = 0; index < diets.length; index++) {
      const element = diets[index]
      let j = Math.floor(index % this.colors.length)

      let v = {
        text: element.name,
        iso: element.iso,
        editable: false,
        color: this.colors[j]
      }
      this.items.push(v)
    }

    if (this.currentOrigins && this.currentOrigins.length > 0) {
      for (let index = 0; index < this.currentOrigins.length; index++) {
        const element = this.currentOrigins[index]

        this.model.push({
          text: element,
          color: 'blue'
        })
      }
      console.log(`${this.currentOrigins.length} currentOrigins loaded`)
    } else {
      console.log('currentOrigins is empty')
    }
  },
  watch: {
    currentOrigins(val, prev) {
      if (!val || val.length == 0) {
        this.model = []
      }
    },
    model(val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            editable: true,
            color: this.colors[this.nonce - 1]
          }

          this.items.push(v)

          this.nonce++
        }

        return v
      })
      let list = val.map(v => {
        if (typeof v !== 'string') {
          return v.text
        }
        return v
      })
      this.$emit('updateDiets', list)
    }
  },

  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      )
    }
  }
}
</script>

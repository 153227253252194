export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]
export const origin = [
  { name: 'American', iso: 'us' },
  { name: 'Chinese', iso: 'cn' },
  { name: 'Indian', iso: 'in' },
  { name: 'Mexican', iso: 'mx' },
  { name: 'Italian', iso: 'it' },
  { name: 'Mediterranean' },
  { name: 'Japanese', iso: 'jp' },
  { name: 'Thai', iso: 'th' },
  { name: 'French', iso: 'fr' },
  { name: 'Ainu' },
  { name: 'Albanian' },
  { name: 'Argentina', iso: 'ar' },
  { name: 'Andhra' },
  { name: 'Anglo-Indian' },
  { name: 'Arab', iso: 'sa' },
  { name: 'Armenian', iso: 'am' },
  { name: 'Assyrian' },
  { name: 'Awadhi' },
  { name: 'Azerbaijani', iso: 'az' },
  { name: 'Balochi' },
  { name: 'Belarusian' },
  { name: 'Bengali' },
  { name: 'Berber' },
  { name: 'Buddhist' },
  { name: 'Bulgarian' },
  { name: 'Cajun' },
  { name: 'Chechen' },
  { name: 'Chinese Islamic' },
  { name: 'Circassian' },
  { name: 'Crimean Tatar' },
  { name: 'Danish', iso: 'dk' },
  { name: 'Estonian' },
  { name: 'Filipino' },
  { name: 'Georgian' },
  { name: 'Goan' },
  { name: 'Goan Catholic' },
  { name: 'Greek', iso: 'gr' },
  { name: 'Hyderabad' },
  { name: 'Indonesian', iso: 'id' },
  { name: 'Inuit' },
  { name: 'Italian American', iso: 'it' },
  { name: 'Italian cuisine', iso: 'it' },
  { name: 'Jewish', iso: 'il' },
  { name: 'Karnataka' },
  { name: 'Kazakh' },
  { name: 'Keralite' },
  { name: 'Korean', iso: 'kr' },
  { name: 'Kurdish' },
  { name: 'Laotian' },
  { name: 'Latvian' },
  { name: 'Lithuanian' },
  { name: 'Louisiana Creole' },
  { name: 'Maharashtrian' },
  { name: 'Mangalorean' },
  { name: 'Malay' },
  { name: 'Malaysian' },
  { name: 'Mordovian' },
  { name: 'Mughal' },
  { name: 'Native American' },
  { name: 'Nepalese' },
  { name: 'New Mexican', iso: 'mx' },
  { name: 'Odia' },
  { name: 'Parsi', iso: 'ir' },
  { name: 'Pashtun' },
  { name: 'Polish' },
  { name: 'Pennsylvania Dutch' },
  { name: 'Pakistani', iso: 'pk' },
  { name: 'Peranakan' },
  { name: 'Persian', iso: 'ir' },
  { name: 'Peruvian', iso: 'pe' },
  { name: 'Portuguese' },
  { name: 'Punjabi' },
  { name: 'Rajasthani' },
  { name: 'Romanian', iso: 'ro' },
  { name: 'Russian', iso: 'ru' },
  { name: 'Sami' },
  { name: 'Serbian' },
  { name: 'Sindhi' },
  { name: 'Slovak' },
  { name: 'Slovenian' },
  { name: 'Somali' },
  { name: 'South Indian', iso: 'in' },
  { name: 'Sri Lankan' },
  { name: 'Tatar' },
  { name: 'Turkish', iso: 'tr' },
  { name: 'Tamil' },
  { name: 'Udupi' },
  { name: 'Ukrainian' },
  { name: 'Yamal' },
  { name: 'Zanzibari' }
]

export const diets = [
  { name: 'Organic' },
  { name: 'Vegan' },
  { name: 'Vegetarian' },
  { name: 'Gluten free' },
  { name: 'Low sodium' },
  { name: 'Low calorie' },
  { name: 'Low curb' },
  { name: 'Dairy free' },
  { name: 'Egg free' },
  { name: 'Peanut Free' },
  { name: 'Soy Free' },
  { name: 'Sugar Free' }
]

export const ingredients = [
  { name: 'sweet potato' },
  { name: 'figs' },
  { name: 'ginger' },
  { name: 'pumpkin' },
  { name: 'burdock root' },
  { name: 'brussels sprouts' },
  { name: 'broccoli' },
  { name: 'cauliflower' },
  { name: 'water chestnuts' },
  { name: 'cantaloupe melons' },
  { name: 'prunes' },
  { name: 'carrots' },
  { name: 'winter squash' },
  { name: 'jalapeno peppers' },
  { name: 'rhubarb' },
  { name: 'pomegranates' },
  { name: 'red currants' },
  { name: 'oranges' },
  { name: 'hubbard squash' },
  { name: 'kumquats' },
  { name: 'sour cherries' },
  { name: 'green beans' },
  { name: 'red leaf lettuce' },
  { name: 'leeks' },
  { name: 'cayenne pepper' },
  { name: 'green kiwifruit' },
  { name: 'golden kiwifruit' },
  { name: 'grapefruit' },
  { name: 'arugula' },
  { name: 'chives' },
  { name: 'paprika' },
  { name: 'red tomatoes' },
  { name: 'green tomatoes' },
  { name: 'green lettuce' },
  { name: 'taro leaves' },
  { name: 'lima beans' },
  { name: 'summer squash' },
  { name: 'navy beans' },
  { name: 'plantain' },
  { name: 'podded peas' },
  { name: 'cowpeas' },
  { name: 'butter lettuce' },
  { name: 'red cherries' },
  { name: 'walnuts' },
  { name: 'fresh spinach' },
  { name: 'parsley' },
  { name: 'chinese cabbage' },
  { name: 'cress' },
  { name: 'apricots' },
  { name: 'coriander' },
  { name: 'romaine lettuce' },
  { name: 'mustard leaves' },
  { name: 'kale' },
  { name: 'broccoli raab' },
  { name: 'chili peppers' },
  { name: 'collards' },
  { name: 'basil' },
  { name: 'chili powder' },
  { name: 'frozen spinach' },
  { name: 'dandelion greens' },
  { name: 'pink grapefruit' },
  { name: 'red cabbage' },
  { name: 'green onion' },
  { name: 'green peas' },
  { name: 'tangerines' },
  { name: 'watercress' },
  { name: 'celery flakes' },
  { name: 'dried parsley' },
  { name: 'beet greens' },
  { name: 'swiss chard' },
  { name: 'pumpkin seeds' },
  { name: 'chia seeds' },
  { name: 'cherimoya' },
  { name: 'almonds' }
]
